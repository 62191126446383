// INPUT FIELDS
const fields = {
  honeyPot: document.querySelector('#_honey'),
  firstName: document.querySelector('#firstName'),
  lastName: document.querySelector('#lastName'),
  email: document.querySelector('#email'),
  phone: document.querySelector("#phone"),
  service: document.querySelector('#selectedService'),
  subject: document.querySelector('#subject'),
  message: document.querySelector('#message'),
  footer_email: document.querySelector('#footer_email'),
  footer_honeyPot: document.querySelector('#_footer_honey'),
};

// ALERT FIELDS
const alerts = {
  firstName: document.querySelector('.firstName-alert'),
  lastName: document.querySelector('.lastName-alert'),
  email: document.querySelector('.email-alert'),
  phone: document.querySelector(".phone-alert"),
  service: document.querySelector('.selectedService-alert'),
  subject: document.querySelector('.subject-alert'),
  message: document.querySelector('.message-alert'),
  footer_email: document.querySelector('.footer-email-alert')
};

// const phoneInputField = document.querySelector("#phone");

// INITIALISE PHONE FIELD
const phoneInput = window.intlTelInput(fields.phone, {
  initialCountry: "co",
  // geoIpLookup: getIp,
  preferredCountries: ["co", "us", "ca", "mx", "es"],
  utilsScript:
    "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
});


// here, the index maps to the error code returned from getValidationError - see readme
var phoneErrorMap = ["Invalid number", "Invalid country code", "Number Too short", "Number Too long", "Invalid number"];

function isNotEmpty(value) {
  let alertMsg = "";
  if (value == null || typeof value == 'undefined'){
    alertMsg += "Please Submit A Text"
  };
  let result = {
    'validation': value.length > 0,
    'alertMsg': alertMsg
  }
  return result
}

function optionSelected(value) {
  // console.log("Option selected: ", value)
  let alertMsg = "";
  let validation = value != "";
  // console.log("Option selected Alert: ", validation.toString())
  if (!validation){
    alertMsg += "Please select an option"
  };
  let result = {
    'validation': validation,
    'alertMsg': alertMsg
  }
  return result
}

function isName(value) {
  function whiteSpace(ogString, appendStr){
    if (ogString !=""){
      ogString += "\n" 
    }
    return ogString += appendStr
  }
  let alertMsg = "";
  let validation = value.length > 1;
  if (value == null || typeof value == 'undefined') 
  {
    alertMsg = whiteSpace(alertMsg, "Please Enter A Name")
    validation = false;
  }
  if (value == "") {
    alertMsg = whiteSpace(alertMsg, "Please Enter A Name")
    validation = false;
  };
  if (/\d/.test(value)) {
    alertMsg = whiteSpace(alertMsg, "Name Contains Numbers")
    validation = false;
  };
  if (value.length <= 1) {
    alertMsg = whiteSpace(alertMsg, "Name Is Too Short")
    validation = false;
  };

  let result = {
    'validation': validation,
    'alertMsg': alertMsg
  }
  return result;
}

function isEmail(email) {
  let alertMsg = "";
  let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  let validation = regex.test(String(email).toLowerCase())
  if (!validation) {
    alertMsg = "Please Enter A Valid Email Adress"
  };
  let result= {
    'validation' :  validation,
    'alertMsg': alertMsg
    }
  return result
}

function phoneValidation(value){
  let alertMsg = "";
  let validation = false;
  if (value.trim()) {
    validation = phoneInput.isValidNumber();
    if (!validation) {
      var errorCode = phoneInput.getValidationError();
      alertMsg = phoneErrorMap[errorCode];
    };
  }

  let result = {
    'validation': validation,
    'alertMsg': alertMsg
  }
  return result
}

function fillAlert(alert, alertMsg){
  alert.style.display = "";
  alert.childNodes[0].textContent = alertMsg
}

function fieldValidation(field_name, validationFunction) {
  let field = fields[field_name];
  let alert = alerts[field_name];
  if (field == null || field == undefined) {
    return false;
  };
  
  // let isFieldValid = validationFunction(field.value, alert)
  let result = validationFunction(field.value)
  let isFieldValid = result['validation']
  if (!isFieldValid) {
    field.classList.add('validation-error');
    if (!(alert == null || alert == undefined)) {
    fillAlert(alert, result['alertMsg'])
    }
  } else {
    field.classList.remove('validation-error');
    alert.style.display = "none";
  }

  return isFieldValid;
}

var reset = function (field, alert) {
  field.classList.remove("validation-error");
  alert.style.display = "none";
  alert.txtContent = '';
};

function resetFields(){
  for (let key of Object.keys(fields)) {
    if(!key.includes("honeyPot")){
    reset(fields[key], alerts[key])
    }
  }
};

function isValid() {
  
  resetFields();
  
  var valid = true;
  
  valid &= !fieldValidation('honeyPot', isNotEmpty)
  valid &= fieldValidation('firstName', isName);
  valid &= fieldValidation('lastName', isName);
  valid &= fieldValidation('email', isEmail);
  valid &= fieldValidation('service', optionSelected);
  valid &= fieldValidation('phone', phoneValidation);
  valid &= fieldValidation('subject', isNotEmpty);
  valid &= fieldValidation('message', isNotEmpty);
  
  return valid;
}
function capitalizeStrings(str){
  var arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

  }
  return arr.join(" ");
}

var recaptchaWidget;

var onSubmit = function (token) {
  alert('success!');
};

var onloadCallback = function () {
  grecaptcha.render('submit', {
    'sitekey': '6Lf4NtkiAAAAAIx6qqNiMM-7wVbACiXf2JWbOLeP',
    'callback': onSubmit
  });
};



$('#contactForm').submit(function(event){
  
  event.preventDefault()  
  let validation = isValid()
  if (validation) {
    fields.phone.value = phoneInput.getNumber();
    let form_data = {}
    for (const [key, value] of Object.entries(fields)) {
      if (key != "footer_email" && !key.includes("honeyPot")){
        form_data[key] = value.value
      }
    }


    grecaptcha.enterprise.ready(function () {
      grecaptcha.enterprise.execute('6LcLk9giAAAAAJiQ0_eJicT-1UscNvQeudmbPT8o', { action: 'submitMainForm' }).then(function (token) {
        let captcha_data = {
          'token': token,
          'siteKey': "6LcLk9giAAAAAJiQ0_eJicT-1UscNvQeudmbPT8o"
        };
        
        $.ajax({
          type: "POST",
          url: "/send_email/captcha_validation.php",
          data: captcha_data,
          success: function (response, status) {
            var result = JSON.parse(response)
            // console.log(result);
            // console.log(result['token'])
            if (result['token'] == token && result['validation']) {
              // $(this).ajaxSubmit(options); 
              form_data["timeStamp"] = new Date().toLocaleDateString("es-CO", {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
              });
              $.ajax({
                type: "POST",
                url: "/send_email/php-mailer.php",
                data: form_data,
                success: function (response, status) {
                  $("#contactForm").trigger("reset");
                  showResponse(response, status);
                },
                error: function (response, status) {
                  showResponse(response, status);
                }
              });
            }
          },
          error: function (response, status) {
            showResponse(response, status);
            alert("Verification failed. Please try again");
          }
        });
    })
  });
};
});
    
// post-submit callback 
function showResponse(responseText, statusText) {

  const myModal = document.getElementById('messageSentModal')
  myModal.querySelector('.modal-title').textContent = capitalizeStrings(statusText)
  myModal.querySelector('.modal-body').textContent = responseText
  myModal.classList.add('show')
  // myModal.setAttribute('aria-hidden','false')
  $('#messageSentModal').modal('show')

  // alert('status: ' + statusText + '\n\nresponseText: \n' + responseText);
} ;

$('#contactFooter').submit(function(event) {
  event.preventDefault();

  let validation = fieldValidation('footer_email', isEmail);
  validation &= !fieldValidation('footer_honeyPot', isNotEmpty);
  if (validation) {
    
    let form_data = {
      'footer_email': fields['footer_email'].value
    }
    
    grecaptcha.enterprise.ready(function () {
      grecaptcha.enterprise.execute('6LcLk9giAAAAAJiQ0_eJicT-1UscNvQeudmbPT8o', { action: 'submtiFooter' }).then(function (token) {
        let captcha_data = {
          'token': token,
          'siteKey': "6LcLk9giAAAAAJiQ0_eJicT-1UscNvQeudmbPT8o"
        };

        $.ajax({
          type: "POST",
          url: "/send_email/captcha_validation.php",
          data: captcha_data,
          success: function (response, status) {
            var result = JSON.parse(response)
            // console.log(result);
            // console.log(result['token'])
            if (result['token'] == token && result['validation']) {
              // $(this).ajaxSubmit(options); 
              form_data["timeStamp"] = new Date().toLocaleDateString("es-CO", {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
              });
              $.ajax({
                type: "POST",
                url: "/send_email/email_only-mailer.php",
                data: form_data,
                success: function (response, status) {
                  $("#contactFooter").trigger("reset");
                  showResponse(response, status);
                },
                error: function (response, status) {
                  showResponse(response, status);
                }
              });
            }
          },
          error: function (response, status) {
            showResponse(response, status);
            alert("Verification failed. Please try again");
          }
        });
      })
    });
  }
  
})



